import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useViewUserMutation } from "../../services/Post";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";

export default function SubscriptionlistofSubscribers() {
  const admintoken = useSelector((data) => data?.local?.token);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [viewUser, { isLoading }] = useViewUserMutation();

  const [userDetails, setUserDetails] = useState("");

  console.log("userDetails", userDetails?.details?.subscriptionDetail);

  useEffect(() => {
    handleViewUser();
  }, []);

  const handleViewUser = async () => {
    const res = await viewUser({ id, admintoken });
    console.log("res", res);
    setUserDetails(res?.data?.results?.user?.[0]);
  };

  function calculatePlanPeriod(planValidFrom, planValidTill) {
    const fromDate = moment(planValidFrom);
    const tillDate = moment(planValidTill);

    const duration = moment.duration(tillDate.diff(fromDate));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();

    return `${years} years, ${months} months, and ${days} days`;
  }

  return (
    <>
      <Header />
      <div className="admin_main">
        <Sidebar Dash={"subs"} />
        <div className="admin_contentpart">
          <div className="row mb-md-4 mb-2">
            <div className="col-auto">
              <a className="back_btn" href="/subscription-details">
                <i className="fas fa-arrow-left" />
              </a>
            </div>
          </div>
          <div className="row comman_design mx-0 mb-4">
            <div className="col-12">
              <div className="row align-items-center justify-content-between py-md-4 py-3 px-md-3 px-2">
                <div className="col-md-auto">
                  <div className="headleft">
                    <h2>{t("Subscriber Details")}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row pb-4 px-md-3 px-2">
                <div className="col-12">
                  <form className="row form-design" action="">
                    <div className="col-12 form-group mb-4">
                      <div className="row border rounded mx-0 px-2 py-3">
                        <div className="form-group col-md-6">
                          <label htmlFor>{t("Name")}</label>
                          {isLoading ? (
                            <Skeleton height={46} />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              value={
                                userDetails?.firstname +
                                  " " +
                                  userDetails?.lastname || t("N/A")
                              }
                              readOnly
                              name="name"
                              id="name"
                            />
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor>{t("Club")}</label>
                          {isLoading ? (
                            <Skeleton height={46} />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              value={
                                userDetails?.details?.schoolChess || t("N/A")
                              }
                              readOnly
                              name="name"
                              id="name"
                            />
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor>Email</label>
                          {isLoading ? (
                            <Skeleton height={46} />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              value={userDetails?.email || t("N/A")}
                              readOnly
                              name="name"
                              id="name"
                            />
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor>Tournament Entry fees paid</label>
                          {isLoading ? (
                            <Skeleton height={46} />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              value={userDetails?.entryfee || t("N/A")}
                              readOnly
                              name="name"
                              id="name"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row comman_design mx-0 mb-3">
                    <div className="col-12">
                      <div className="row align-items-center justify-content-between py-md-4 py-3 px-md-3 px-2">
                        <div className="col-md-auto mb-md-0 mb-3">
                          <div className="headleft">
                            <h2>Details</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row comman_table pt-md-3 pb-md-4 pb-3 px-md-3 px-2">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>{t("S.No.")}</th>
                                  <th>{t("Start Date")}</th>
                                  <th>{t("Age")}</th>
                                  <th>{t("Phone Number")} </th>
                                  <th>{t("Rating")}</th>
                                  <th>{t("Paypal ID")}</th>
                                  <th>{t("Wins")}</th>
                                  <th>{t("Losses")}</th>
                                  <th>{t("Draws")}</th>
                                  <th>
                                    {t("Total Games")} <br /> {t("Played")}
                                  </th>
                                  {/* <th>
                                                       Time Subscribed <br /> (In Year)
                                                     </th> */}
                                  <th>
                                    {t("Subscription")} <br /> {t("Level")}
                                  </th>
                                  <th>
                                    {t("Subscription Period")} <br /> (
                                    {t("In Year")})
                                  </th>
                                  <th>{t("Team Board No")}</th>
                                  <th>{t("No Of Friends")}</th>
                                  <th>
                                    {t("Games Played")} <br />{" "}
                                    {t("With Friends")}
                                  </th>
                                  <th>
                                    {t("Games Played")} <br /> {t("With Bot")}
                                  </th>
                                  <th>
                                    {t("No Of Leagues")} <br /> {t("Played")}
                                  </th>
                                  <th>
                                    {t("No Of Sessions With")} <br />{" "}
                                    {t("Team Coach")}
                                  </th>
                                  <th>
                                    {t("No Of Sessions With")} <br />{" "}
                                    {t("Individual Coach")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    {moment(userDetails?.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    {userDetails?.details?.age || t("N/A")}{" "}
                                  </td>
                                  <td>
                                    {(userDetails?.countrycode &&
                                      userDetails?.phonenumber &&
                                      `${userDetails?.countrycode} ${userDetails?.phonenumber}`) ||
                                      t("N/A")}{" "}
                                  </td>
                                  <td>
                                    {userDetails?.details?.rating?.toFixed(0) ||
                                      t("N/A")}
                                  </td>
                                  <td>
                                    {userDetails?.details?.paypalId || "N/A"}{" "}
                                  </td>
                                  <td>{userDetails?.details?.wins || 0} </td>
                                  <td>{userDetails?.details?.loss || 0} </td>
                                  <td>{userDetails?.details?.draw || 0} </td>
                                  <td>
                                    {userDetails?.details?.totalMatches || 0}{" "}
                                  </td>
                                  <td>{userDetails?.details?.planId || 0} </td>
                                  <td>
                                    {userDetails?.details?.planValidFrom &&
                                    userDetails?.details?.planValidTill
                                      ? calculatePlanPeriod(
                                          userDetails.details.planValidFrom,
                                          userDetails.details.planValidTill
                                        )
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {userDetails?.details?.chessboard || 0}{" "}
                                  </td>
                                  <td>{userDetails?.details?.win || 0} </td>
                                  <td>
                                    {userDetails?.details?.friendMatches || 0}{" "}
                                  </td>
                                  <td>
                                    {userDetails?.details?.botMatches || 0}{" "}
                                  </td>
                                  <td>
                                    {userDetails?.details?.leagueMatches || 0}{" "}
                                  </td>
                                  <td>
                                    {userDetails?.details?.teamSessions || 0}{" "}
                                  </td>
                                  <td>
                                    {userDetails?.details?.individualSession ||
                                      0}{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade fitermoddel act_commisnior"
        id="Commissioner"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Act As Commissioner
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body px-4 py-4">
              <form className="form-design row" action="#">
                <div className="form-group px-2 col-md-12">
                  <label htmlFor>User Name</label>
                  <input className="form-control" type="text" name id />
                </div>
                <div className="form-group px-2 col-md-12 mb-4">
                  <label htmlFor>Super Password</label>
                  <input className="form-control" type="text" name id />
                </div>
                <div className="form-group px-2 col-md-12 mb-0 mt-1">
                  <a className="comman_btn" href="javascript:;">
                    Login
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
