import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const PostApi = createApi({
  reducerPath: "PostApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_APIENDPOINT}`,
  }),
  endpoints: (builder) => ({
    adminLogin: builder.mutation({
      query: ({ header, ...body }) => {
        return {
          url: "auth/login",
          method: "PUT",
          body,
          headers: {
            "x-auth-user-type": header,
          },
        };
      },
    }),
    forgetPassword: builder.mutation({
      query: ({ header, ...body }) => {
        return {
          url: "auth/forgotPassword",
          method: "PUT",
          body,
          headers: {
            "x-auth-user-type": header,
          },
        };
      },
    }),
    verifyOtp: builder.mutation({
      query: (body) => {
        return {
          url: "auth/verifyOTP",
          method: "POST",
          body,
          headers: {
            "x-auth-user-type": "admin",
          },
        };
      },
    }),
    commissionerverifyOtp: builder.mutation({
      query: (body) => {
        return {
          url: "auth/verifyOTP",
          method: "POST",
          body,
          headers: {
            "x-auth-user-type": "commissioner",
          },
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (body) => {
        return {
          url: "auth/updatePassword",
          method: "PUT",
          body,
          headers: {
            "x-auth-user-type": "admin",
          },
        };
      },
    }),
    commissionerresetPassword: builder.mutation({
      query: (body) => {
        return {
          url: "auth/updatePassword",
          method: "PUT",
          body,
          headers: {
            "x-auth-user-type": "commissioner",
          },
        };
      },
    }),
    changeLanguage: builder.mutation({
      query: (body) => {
        const { admintoken, language } = body;
        return {
          url: "user/changeLanguage",
          method: "PUT",
          body: { language },
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    changePassword: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "auth/changePassword",
          method: "PUT",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    ticketReply: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "/account/ticketReply",
          method: "PUT",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    setMasterPassword: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "user/admin/setMasterPassword",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getAllTransactions: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "payment/getAllTransactions",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getAllCheats: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "game/getAllCheats",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    getUserView: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/getMyProfile",
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    exportTransactions: builder.mutation({
      query: (body) => {
        const { admintoken } = body;

        return {
          url: "payment/exportTransactions",
          method: "GET",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSchoolsList: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/getSchoolsList",
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    dashboard: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "analytics/admin/getRecentStudents",
          body: data,
          method: "PATCH",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    subscription: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "subscription/getPlans",
          body: data,
          method: "PATCH",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    commissionerLeagues: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/commissionerLeagues",
          body: data,
          method: "PATCH",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCoaches: builder.query({
      query: (body) => {
        const { admintoken } = body;

        return {
          url: "coaching/getCoaches",
          method: "PATCH",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getAllCoaches: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "/coaching/getAllCoaches",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getUsers: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/admin/getUsers",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getContent: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "account/getContent",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getAllSchools: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/admin/getAllSchools",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getStatistics: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "analytics/admin/getStatistics",
          method: "GET",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    dashchangeStatus: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `coaching/changeStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewContent: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `account/viewContent/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCertificateById: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `account/getCertificateById/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    changeStatus: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/admin/changeStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    updateProfile: builder.mutation({
      query: ({ admintoken, userId, alldata }) => ({
        url: `user/admin/editUser/${userId}`,
        method: "PUT",
        body: alldata,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    editPlan: builder.mutation({
      query: ({ admintoken, userId, alldata }) => ({
        url: `subscription/editPlan/${userId}`,
        method: "PUT",
        body: alldata,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    ticketChangeStatus: builder.mutation({
      query: ({ admintoken, userId, alldata }) => ({
        url: `account/changeStatus/${userId}`,
        method: "PATCH",
        body: alldata,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    editContent: builder.mutation({
      query: ({ admintoken, userId, alldata }) => ({
        url: `account/editContent`,
        method: "PUT",
        body: alldata,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    editSessionPrice: builder.mutation({
      query: ({ admintoken, id, allData }) => ({
        url: `coaching/editSessionPrice/${id}`,
        method: "PUT",
        body: allData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    viewCoach: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `coaching/viewCoach/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewUser: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/admin/viewUser/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewTournament: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/viewTournament/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewTicket: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `account/viewTicket/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewSessionPrice: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `coaching/viewSessionPrice/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getBackupMangers: builder.mutation({
      query: (body) => {
        const { id, admintoken, ...data } = body;

        return {
          url: `user/admin/getBackupMangers/${id}`,
          method: "PATCH",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCoachPayouts: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `subscription/getCoachPayouts`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewCoachSessions: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `/subscription/viewCoachSessions`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    addCoach: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "coaching/addCoach",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getAllMatches: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "player/getAllMatches",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getMySessions: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "coaching/getMySessions",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getLeagueDropdown: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "subscription/getLeagueDropdown",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getSessionDropdown: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "subscription/getSessionDropdown",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getCommissionerPayouts: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "subscription/getCommissionerPayouts",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    payToCoach: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "subscription/pay",
        method: "POST",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    commissionerPay: builder.mutation({
      query: ({ allData, admintoken }) => ({
        url: "subscription/pay",
        method: "POST",
        body: allData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    addCommissioner: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "user/admin/addCommissioner",
        method: "PATCH",
        body: formData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getLeagues: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getLeagues",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getTickets: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "account/getTickets",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSponsorslist: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getSponsors",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSponsors: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getAllTournaments",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCoachClients: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "coaching/getCoachClients",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewCoachClient: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "coaching/viewCoachClient",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getAllTournaments: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getAllTournaments",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getManagerTournaments: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getManagerTournaments ",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getAssignedCoaches: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/getAssignedCoaches",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getLeagueSchools: builder.query({
      query: (body) => {
        const { id, admintoken, ...data } = body;

        return {
          url: `tournament/getLeagueSchools/${id}`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getBackupMangersformer: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `user/admin/getBackupMangers/${id}`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSchoolList: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `user/getSchoolList`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSchoolStudents: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `user/admin/getSchoolStudents`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSchoolTeams: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `user/admin/getSchoolTeams`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getPushNotifications: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `notification/getPushNotifications`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    getNotifications: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `notification/getNotifications`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewTeam: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `user/admin/viewTeam`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getPayouts: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `subscription/getPayouts`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewPayoutLeagues: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `subscription/viewPayoutLeagues`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSchedules: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `tournament/getSchedules`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getLeaderboard: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `player/getLeaderboard`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewLeague: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/viewLeague/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSessionPrice: builder.mutation({
      query: (body) => {
        const { allData, admintoken } = body;

        return {
          url: `coaching/getSessionPrice`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewPlan: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `subscription/viewPlan/${id}`,
          method: "GET",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewSponsor: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/viewSponsor/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    readNotifications: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `notification/readNotifications`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    readNotificationId: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `notification/readNotification/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCityFilter: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/admin/getCityFilter`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    tournamentchangeStatus: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/changeStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewCheat: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `game/viewCheat/${id}`,
          method: "GET",
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    changePlanStatus: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `subscription/changePlanStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "admin",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    createPushNotifictaion: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "notification/createPushNotifictaion",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    setSessionPrice: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "coaching/setSessionPrice",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `user/admin/deleteUser/${id}`,
        method: "GET",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deletePlan: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `user/admin/deletePlan/${id}`,
        method: "GET",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteBackupManger: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `user/admin/deleteBackupManger/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteSponsor: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `tournament/deleteSponsor/${id}`,
        method: "GET",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    removeSponsor: builder.mutation({
      query: ({ id, admintoken, sponsorId }) => ({
        url: `tournament/removeSponsor/${id}`,
        method: "PATCH",
        body: { sponsorId },
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getUserCertificates: builder.mutation({
      query: ({ admintoken, data }) => ({
        url: `account/getUserCertificates`,
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteLeague: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `tournament/deleteLeague/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteTournament: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `tournament/deleteTournament/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deletePushNotification: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `notification/deletePushNotification/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteNotificationSingle: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `notification/deleteNotification/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteSessionPrice: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `coaching/deleteSessionPrice/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteNotifications: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `notification/deleteNotifications`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    addSponsor: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "tournament/addSponsor",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "admin",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useForgetPasswordMutation,
  useVerifyOtpMutation,
  useCommissionerverifyOtpMutation,
  useResetPasswordMutation,
  useSetMasterPasswordMutation,
  useCommissionerresetPasswordMutation,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useGetUserViewQuery,
  useDashboardQuery,
  useChangeLanguageMutation,
  useGetStatisticsQuery,
  useChangeStatusMutation,
  useGetCoachesQuery,
  useViewCoachMutation,
  useAddCoachMutation,
  useGetUsersQuery,
  useViewUserMutation,
  useAddCommissionerMutation,
  useDashchangeStatusMutation,
  useDeleteUserMutation,
  useGetBackupMangersMutation,
  useGetLeaguesQuery,
  useViewLeagueMutation,
  useTournamentchangeStatusMutation,
  useDeleteBackupMangerMutation,
  useViewSponsorMutation,
  useAddSponsorMutation,
  useGetSponsorsQuery,
  useDeleteSponsorMutation,
  useCommissionerLeaguesQuery,
  useSubscriptionQuery,
  useDeletePlanMutation,
  useViewPlanMutation,
  useGetManagerTournamentsQuery,
  useViewTournamentMutation,
  useDeleteTournamentMutation,
  useGetSponsorslistQuery,
  useEditPlanMutation,
  useGetSchoolsListQuery,
  useGetBackupMangersformerQuery,
  useGetSchoolStudentsQuery,
  useGetLeagueSchoolsQuery,
  useChangePlanStatusMutation,
  useCreatePushNotifictaionMutation,
  useGetPushNotificationsQuery,
  useGetNotificationsMutation,
  useDeletePushNotificationMutation,
  useDeleteNotificationsMutation,
  useDeleteNotificationSingleMutation,
  useReadNotificationsMutation,
  useGetContentQuery,
  useViewContentMutation,
  useEditContentMutation,
  useGetTicketsQuery,
  useViewTicketMutation,
  useTicketChangeStatusMutation,
  useGetAllSchoolsQuery,
  useGetCityFilterMutation,
  useDeleteLeagueMutation,
  useTicketReplyMutation,
  useRemoveSponsorMutation,
  useGetAllTransactionsMutation,
  useCommissionerPayMutation,
  useGetPayoutsMutation,
  useGetSchedulesMutation,
  useGetLeaderboardMutation,
  useGetAllCoachesQuery,
  useGetAllMatchesMutation,
  useGetMySessionsMutation,
  useGetLeagueDropdownMutation,
  useGetSessionDropdownMutation,
  useGetCommissionerPayoutsMutation,
  usePayToCoachMutation,
  useViewPayoutLeaguesMutation,
  useGetAllTournamentsQuery,
  useGetCoachPayoutsMutation,
  useViewCoachSessionsMutation,
  useGetUserCertificatesMutation,
  useGetCertificateByIdMutation,
  useSetSessionPriceMutation,
  useGetSessionPriceMutation,
  useDeleteSessionPriceMutation,
  useViewSessionPriceMutation,
  useEditSessionPriceMutation,
  useExportTransactionsMutation,
  useGetAllCheatsMutation,
  useViewCheatMutation,
  useGetSchoolListQuery,
  useGetAssignedCoachesQuery,
  useViewTeamMutation,
  useGetSchoolTeamsQuery,
  useGetCoachClientsMutation,
  useViewCoachClientMutation,
  useReadNotificationIdMutation,
} = PostApi;
