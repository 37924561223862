import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AdminRoutes from "./routes/AdminRoutes";
import CommissionerRoutes from "./routes/CommissionerRoutes";
import NetworkStatus from "./components/network/NetworkStatus";
import Adminlogin from "./components/admin/Adminlogin";
import ForgetPassword from "./components/admin/ForgetPassword";
import Language from "./components/admin/Language";
import { initializeApp } from "firebase/app";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  useGetUserViewQuery,
  useReadNotificationsMutation,
} from "./services/Post";

function App() {
  const { t, i18n } = useTranslation();
  const admintoken = useSelector((data) => data?.local?.token);
  const userid = useSelector((data) => data?.local?.userid);
  const userType = useSelector((data) => data?.local?.usertype);

  const { data: adminDetails, refetch: fetchDetails } = useGetUserViewQuery({
    admintoken,
    userId: userid,
  });

  const config = {
    apiKey: "AIzaSyBSZ2Dk1ztdqMq2qPXTmR9TqHQ7CxCBtHU",
    authDomain: "mind-league.firebaseapp.com",
    projectId: "mind-league",
    storageBucket: "mind-league.firebasestorage.app",
    messagingSenderId: "237785394353",
    appId: "1:237785394353:web:7b1f7c134fca70157a8690",
    measurementId: "G-HZVVW5D5N5",
  };

  useEffect(() => {
    import("firebase/messaging").then(
      ({ getMessaging, getToken, onMessage, isSupported }) => {
        const app = initializeApp(config);
        const messaging = getMessaging(app);

        isSupported().then((support) => {
          if (support) {
            if (Notification.permission === "default") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  console.log("Notification permission granted");
                  getFirebaseToken();
                } else {
                  console.log("Notification permission denied");
                }
              });
            } else if (Notification.permission === "granted") {
              getFirebaseToken();
            } else {
              console.log("Notification permission not granted");
            }
          }
        });

        const getFirebaseToken = () => {
          getToken(messaging, {
            vapidKey: `${process.env.REACT_APP_VALIDFCMTOKENKEY}`,
          })
            .then((currentToken) => {
              if (currentToken) {
                localStorage.setItem("fcmTokenMl", currentToken);
              } else {
                console.log("No registration token available.");
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });
        };

        onMessage(messaging, (payload) => {
          const { body_en, body_he, type, id, image } = payload.data;
          const options = {
            body:
              i18n.language === "he"
                ? body_he
                : body_en || payload.notification.body,
            icon: payload.notification.icon,
            image: payload.notification.image,
            dir: i18n.language === "he" ? "rtl" : "ltr",
            // vibrate: true,
            vibrate: [200, 100, 200],
            silent: false,
          };
          let notification = new Notification(
            payload.notification.title,
            options
          );

          console.log("payload", payload);

          notification.onclick = function (event) {
            event.preventDefault();
            let url = getNavigationUrl(type, id);
            if (url) {
              const link = document.createElement("a");
              link.href = url;
              link.target = "_self";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }

            redirect(payload.data.id);
            fetchDetails();
          };

          localStorage.setItem(
            "notii",
            localStorage.getItem("notii") === "true" ? "false" : "true"
          );
        });
      }
    );

    if (!localStorage.getItem("uidMl")) {
      localStorage.setItem("uidMl", uuidv4());
    }
  }, [i18n.language]);

  const [readNoti] = useReadNotificationsMutation();

  const redirect = async (id) => {
    const { data } = await readNoti(id);
  };

  const getNavigationUrl = (type, id) => {
    if (type === "NEW_STUDENT") {
      return `/studentplayer-details/${id}`;
    } else if (type === "NEW_ALUMNI") {
      return `/alumni-details/${id}`;
    } else if (type === "NEW_MANAGER") {
      return `/team-managers-details/${id}`;
    } else if (type === "PAY_TOURNAMENT") {
      return `/transaction-management`;
    } else if (type === "PAY_COACH") {
      return `/transaction-management`;
    } else if (type === "BUY_PLAN") {
      return `/subscription-plan-management`;
    } else if (type === "PAY_LEAGUE") {
      return `/transaction-management`;
    } else if (type === "CHEATING") {
      return `/cheat-detection-managment`;
    } else if (type === "REJECT_LEAGUE") {
      return `/league-management`;
    } else if (type === "ACCEPT_LEAGUE") {
      return `/league-management`;
    } else if (type === "IN_PERSON_RECORD") {
      return `/all-notifications`;
    } else if (type === "LEAGUE_SCHEDULE") {
      return `/league-management`;
    } else if (type === "LIVE") {
      return `/all-notifications`;
    } else {
      return `/dashboard`;
    }
  };

  return (
    <div dir={i18n.language === "he" ? "rtl" : "ltr"}>
      <BrowserRouter>
        <ToastContainer />
        <NetworkStatus />
        <Routes>
          <Route path="/" element={<Language />} />
          <Route path="/login" element={<Adminlogin />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />

          {userType === "admin" ? (
            <Route path="/*" element={<AdminRoutes />} />
          ) : (
            <Route path="/*" element={<CommissionerRoutes />} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
