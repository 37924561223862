import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setHeader, setProfiledropdown } from "../../app/slices/localSlice";
import { useTranslation } from "react-i18next";
import {
  useChangeLanguageMutation,
  useGetNotificationsMutation,
  useGetUserViewQuery,
  useReadNotificationIdMutation,
  useReadNotificationsMutation,
} from "../../services/Post";
import { toast } from "react-toastify";
import "./bell.css";
import { Spinner } from "react-bootstrap";

function Header() {
  const sw = useSelector((data) => data?.local?.profiledrop);
  const admintoken = useSelector((data) => data?.local?.token);
  const userid = useSelector((data) => data?.local?.userid);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [changelang] = useChangeLanguageMutation();

  const { data: adminDetails, refetch: fetchDetails } = useGetUserViewQuery({
    admintoken,
    userId: userid,
  });

  const { t, i18n } = useTranslation();

  const changeLanguage = async (lang) => {
    i18n.changeLanguage(lang !== "Hebrew" ? "en" : "he");

    const alldata = {
      admintoken,
      language: lang,
    };

    const res = await changelang(alldata);
    if (res?.data?.error || res?.error?.data?.error) {
      toast.error(res?.data?.message || res?.error?.data?.message);
    } else {
      toast.success(res?.data?.message);
    }
    console.log("res", res);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [details, setDetails] = useState("");

  const notificationCount =
    details?.results?.profile?.details?.notificationCount;

  useEffect(() => {
    if (adminDetails) {
      console.log("adminDetails", adminDetails);

      setDetails(adminDetails);
      const adminData = adminDetails?.results?.profile;
    }
  }, [adminDetails]);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    dispatch(setHeader(sidebarCollapsed ? "" : "ml"));
  };

  const toggleProfileDropdown = () => {
    dispatch(setProfiledropdown(sw ? "" : "sw"));
  };

  const handleLogout = (e) => {
    e.preventDefault();

    Swal.fire({
      title: t("Logout Confirmation"),
      text: t("Are you sure you want to logout?"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("Yes, Logout"),
      cancelButtonText: t("No, Stay Logged In"),
      // reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const rememberMe = localStorage.getItem("rememberMe");
        const rememberedEmail = localStorage.getItem("rememberedEmail");

        localStorage.clear();

        if (rememberMe) {
          localStorage.setItem("rememberMe", rememberMe);
        }
        if (rememberedEmail) {
          localStorage.setItem("rememberedEmail", rememberedEmail);
        }
        navigate("/login");
        Swal.fire({
          title: t("Logged Out"),
          text: t("You have been successfully logged out."),
          icon: "success",
          confirmButtonText: t("OK"),
        });
      } else {
        Swal.fire({
          title: t("Cancelled"),
          text: t("You are still logged in."),
          icon: "info",
          confirmButtonText: t("OK"),
        });
      }
    });
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const [viewNotification, { isLoading: loader }] =
    useGetNotificationsMutation();
  const [readNoti, { isLoading: loaders }] = useReadNotificationsMutation();
  const [readNotiid, { isLoading: loadersid }] =
    useReadNotificationIdMutation();

  const [notiList, setNotiList] = useState([]);

  // useEffect(() => {
  //   handleviewNoti();
  // }, []);

  const handleviewNoti = async () => {
    const res = await viewNotification({
      admintoken,
      page: 1,
      pazeSize: 10,
    });
    if (res?.data?.error || res?.error?.data?.error) {
      toast.error(res?.data?.error || res?.error?.data?.error);
    } else {
      setNotiList(res?.data?.results?.notifications);
    }
  };
  const handleReadNoti = async () => {
    const res = await readNoti({ admintoken });
    if (res?.data?.error || res?.error?.data?.error) {
      toast.error(res?.data?.error || res?.error?.data?.error);
    } else {
      handleviewNoti();
      fetchDetails();
    }
  };

  const handleReadNotiid = async (id) => {
    const res = await readNotiid({ id, admintoken });
    if (res?.data?.error || res?.error?.data?.error) {
      toast.error(res?.data?.error || res?.error?.data?.error);
    } else {
      fetchDetails();
      console.log("success");
      handleviewNoti();
    }
  };

  const handleNavigate = (type, id, notiid) => {
    handleReadNotiid(notiid);
    if (type === "NEW_STUDENT") {
      navigate(`/studentplayer-details/${id}`);
    } else if (type === "NEW_ALUMNI") {
      navigate(`/alumni-details/${id}`);
    } else if (type === "NEW_MANAGER") {
      navigate(`/team-managers-details/${id}`);
    } else if (type === "PAY_TOURNAMENT") {
      navigate(`/transaction-management`);
    } else if (type === "PAY_COACH") {
      navigate(`/transaction-management`);
    } else if (type === "BUY_PLAN") {
      navigate(`/subscription-plan-management`);
    } else if (type === "PAY_LEAGUE") {
      navigate(`/transaction-management`);
    } else if (type === "CHEATING") {
      navigate(`/cheat-detection-managment`);
    } else {
      navigate(`/all-notifications`);
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <div className="admin_header">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto text-center">
            <Link className="logo" to="/dashboard">
              <img src={require("../../assets/img/logo.png")} alt="" />
            </Link>
          </div>
          <div className="col-auto text-center">
            <div className="pagename">{t("Admin Panel")}</div>
          </div>
          <div className="col-auto">
            <div className="row">
              <div
                className="col-auto pe-2 position-relative"
                onClick={() => {
                  toggleDropdown();
                  handleReadNoti();
                  handleviewNoti();
                }}
              >
                <i
                  className={`far fa-bell ${
                    notificationCount > 0 ? "bell-pulse" : ""
                  }`}
                  style={{
                    color: "white",
                    fontSize: "38px",
                    cursor: "pointer",
                    transition: "transform 0.3s ease",
                  }}
                ></i>
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                  style={{
                    fontSize: "12px",
                    display: notificationCount === 0 ? "none" : "",
                  }}
                >
                  {notificationCount}
                  <span className="visually-hidden">
                    {t("Unread Notifications")}
                  </span>
                </span>

                {isDropdownVisible && (
                  <div
                    className="notification-dropdown position-absolute"
                    style={{
                      top: "100%",
                      // right: "0",
                      right: i18n.language === "he" ? "auto" : "0",
                      left: i18n.language === "he" ? "0" : "auto",
                      width: "300px",
                      backgroundColor: "#fff",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                      zIndex: "1000",
                    }}
                  >
                    <ul
                      className="list-group list-group-flush"
                      style={{ borderRadius: "8px" }}
                    >
                      {loader ? (
                        <div className="d-flex align-items-center justify-content-center my-5">
                          <Spinner />
                        </div>
                      ) : notiList?.length > 0 ? (
                        notiList?.slice(0, 4)?.map((notification, index) => {
                          const timeElapsed = new Date(
                            notification.createdat
                          ).toLocaleString();
                          const isUnread = !notification.isread;
                          return (
                            <li
                              className={`list-group-item ${
                                isUnread ? "bg-light" : ""
                              }`}
                              key={index}
                              style={{
                                borderLeft: isUnread
                                  ? "5px solid #007bff"
                                  : "5px solid transparent",
                                borderRadius: "8px",
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      notification?.sender?.profileimage ||
                                      require("../../assets/img/favicon3.png")
                                    }
                                    alt="Profile"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <div>
                                    <div className="fw-bold">
                                      {notification.sender?.firstname ||
                                        "Unknown"}{" "}
                                      -{" "}
                                      {/* {notification.type === "NEW_STUDENT"
                                        ? "New Student"
                                        : notification.type === "NEW_ALUMNI"
                                        ? "New Alumni"
                                        : notification.type === "NEW_MANAGER"
                                        ? "New Manager"
                                        : notification?.type ===
                                          "PAY_TOURNAMENT"
                                        ? "Pay Tournament "
                                        : notification?.type === "PAY_COACH"
                                        ? "Pay Coach"
                                        : notification?.type === "BUY_PLAN"
                                        ? "Buy Plan"
                                        : notification?.type === "PAY_LEAGUE"
                                        ? "Pay League"
                                        : notification?.type === "CHEATING"
                                        ? "Cheating"
                                        : "Notification"} */}
                                      {notification?.type}
                                    </div>
                                    <div
                                      className="text-muted"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {timeElapsed}
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                      {i18n.language === "he"
                                        ? notification.description_he
                                        : notification.description_en}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary btn-xs me-2"
                                    style={{
                                      padding: "2px 6px",
                                      fontSize: "10px",
                                      lineHeight: "1.5",
                                    }}
                                    aria-label="Navigate"
                                    onClick={() =>
                                      handleNavigate(
                                        notification.type,
                                        notification.notificationid,
                                        notification?.id
                                      )
                                    }
                                  >
                                    <i className="fa fa-arrow-right" />
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className="d-flex align-items-center justify-content-center fw-bold text-danger my-5">
                          {t("No Notifications Found")}
                        </div>
                      )}

                      <li
                        className="list-group-item text-center"
                        style={{ display: notiList?.length > 0 ? "" : "none" }}
                      >
                        <a href="/all-notifications">
                          {t("View All Notifications")}
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="col-auto pe-2">
                <a
                  className="language_change"
                  href="#"
                  id="languageDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      i18n.language !== "he"
                        ? require("../../assets/img/united-kingdom.png")
                        : require("../../assets/img/Israle__81703.jpg")
                    }
                    alt="Hebrew"
                  />{" "}
                  {i18n.language !== "he" ? t("English") : t("Hebrew")}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="languageDropdown"
                >
                  <li>
                    <a
                      className="language_change"
                      href="#"
                      onClick={() =>
                        changeLanguage(
                          i18n.language !== "he" ? "Hebrew" : "English"
                        )
                      }
                    >
                      <img
                        src={
                          i18n.language !== "he"
                            ? require("../../assets/img/Israle__81703.jpg")
                            : require("../../assets/img/united-kingdom.png")
                        }
                        alt="English"
                      />
                      {i18n.language !== "he" ? t("Hebrew") : t("English")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-auto d-flex align-items-center">
                <div className="dropdown Profile_dropdown">
                  <button
                    className={`btn btn-secondary ${sw ? "show" : ""}`}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleProfileDropdown}
                  >
                    <>
                      {details?.results?.profile?.profileimage ? (
                        <img
                          className=""
                          src={details.results.profile.profileimage}
                          alt="Profile"
                        />
                      ) : (
                        <i className="fas fa-user-circle fa-2x"></i>
                      )}
                    </>
                  </button>
                  <ul
                    className={`dropdown-menu ${sw ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="/view-profile"
                        onClick={toggleProfileDropdown}
                      >
                        {t("View Profile")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/change-password"
                        onClick={toggleProfileDropdown}
                      >
                        {t("Change Password")}
                      </a>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={(e) => {
                          handleLogout(e);
                          toggleProfileDropdown();
                        }}
                      >
                        {t("Logout")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="toggle_btns" onClick={toggleSidebar}>
                  <i className="fas fa-bars" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
