import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForgetPasswordMutation } from "../../services/Post";
import { useForm } from "react-hook-form";
import { setCommissionerEmail, setUserType } from "../../app/slices/localSlice";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Spinner } from "react-bootstrap";

function ForgetPassword() {
  const [loader, setLoader] = useState(false);
  const [sendMail, { isLoading: loaders, isSuccess, isError }] =
    useForgetPasswordMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    trigger,
    watch,
  } = useForm();

  const handleSaveChanges = async (data) => {
    const userRole = window.location.href.includes("commissioner")
      ? "commissioner"
      : "admin";
    try {
      setLoader(true);
      const res = await sendMail({
        email: data.email,
        language: i18n.language === "he" ? "Hebrew" : "English",
        header: userRole,
      });

      console.log("res foeget", res);

      dispatch(setCommissionerEmail(res?.data?.results?.user?.email));
      dispatch(setUserType(res?.data?.results?.user?.usertype));
      if (res?.data?.error || res?.error?.data?.error) {
        toast.error(res?.data?.message || res?.error?.data?.message);
      } else {
        Swal.fire({
          icon: "success",
          title: res?.data?.message,
          confirmButtonText: t("OK"),
          html: `
            ${t(`OTP is sent to your email`)} 
            <a href="mailto:${
              res?.data?.results?.user?.email
            }" style="color: blue; text-decoration: underline !important;">
              ${res?.data?.results?.user?.email}
            </a>. ${t(
              "Please check your email. Your OTP will expire in 5 minutes."
            )}
          `,
        });
        navigate("/verification");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  return (
    <section className="login_page">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-10 col-11">
            <div className="login_top shadow">
              <div className="login_page_form">
                <div className="row">
                  <div className="col-12 formheader mb-4 text-center">
                    <h1>{t("Forgot Password")}</h1>
                    <p>
                      {t(
                        "Please enter your registered Email Address to receive the OTP"
                      )}
                    </p>
                  </div>
                  <div className="col-12">
                    <form
                      className="row form-design"
                      action=""
                      onSubmit={handleSubmit(handleSaveChanges)}
                    >
                      <div className="form-group col-12">
                        <label htmlFor="email">{t("Email")}</label>
                        <input
                          type="email"
                          // className="form-control"
                          className={classNames("form-control  ", {
                            "is-invalid": errors.email,
                          })}
                          id="email"
                          name="email"
                          placeholder={t("Type your email Id")}
                          {...register("email", {
                            required: t("Email is Required*"),
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t("Invalid email address"),
                            },
                          })}
                        />
                        {errors.email && (
                          <small className="errorText mx-0 fw-bold text-danger">
                            {errors.email?.message}
                          </small>
                        )}
                      </div>
                      <div className="form-group col-12 mb-0 mt-2">
                        <button
                          type={loader ? "button" : "submit"}
                          className="comman_btn border-0"
                          style={{
                            cursor: loader ? "not-allowed" : "pointer",
                          }}
                        >
                          <span>
                            {loaders ? (
                              <div className="d-flex align-items-center fw-bold justify-content-center">
                                {t("Wait")}{" "}
                                <Spinner
                                  style={{
                                    fontSize: "2em",
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "5px",
                                  }}
                                />
                              </div>
                            ) : (
                              t("Send Recovery Email")
                            )}
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgetPassword;
