import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSubscriptionQuery } from "../../services/Post"; // Import your query or API service

// Async thunk to fetch subscription data
export const fetchSubscriptionData = createAsyncThunk(
  "subscription/fetchData",
  async ({ admintoken, page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await useSubscriptionQuery({
        admintoken,
        page,
        pageSize,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptionData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchSubscriptionData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default subscriptionSlice.reducer;
