import React, { useEffect, useState } from "react";
import { useAdminLoginMutation } from "../../services/Post";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { setToken, setUserId, setUserType } from "../../app/slices/localSlice";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

function Adminlogin() {
  const { t, i18n } = useTranslation();
  const [login, { isLoading: loader }] = useAdminLoginMutation();

  const rememberMelocal = localStorage?.getItem("rememberMe");

  let sessionId = localStorage.getItem("sessionId");

  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("sessionId", sessionId);
  }

  const [rememberMe, setRememberMe] = useState(
    rememberMelocal === "true" ? true : false
  );

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const rememberPreference = localStorage.getItem("rememberedEmail");
    const rememberMe = localStorage?.getItem("rememberMe");
    if (rememberPreference) {
      reset({
        email: localStorage.getItem("rememberedEmail"),
        // password: localStorage.getItem("rememberedPassword"),
      });
    }
    if (rememberMe === true) {
      setRememberMe(true);
    }
  }, []);

  const onSubmit = async (data) => {
    const userRole = window.location.href.includes("commissioner")
      ? "commissioner"
      : "admin";
    const fcmToken = localStorage.getItem("fcmTokenMl");

    const logData = {
      email: data?.email,
      password: data?.password,
      language: i18n.language === "he" ? "Hebrew" : "English",
      fcmToken: fcmToken ? fcmToken : "",
      deviceId: sessionId,
      deviceOS: "Web",
      header: userRole,
    };

    try {
      const res = await login(logData);

      if (res?.error?.data?.error || res?.data?.error) {
        Swal.fire({
          icon: "error",
          title: t("Login Failed !"),
          text: res?.error?.data?.message || res?.data?.message,
          showConfirmButton: true,
        });
      } else {
        dispatch(setToken(res?.data?.results?.token));
        dispatch(setUserId(res?.data?.results?.user?.id));
        dispatch(setUserType(res?.data?.results?.userType));
        Swal.fire({
          icon: "success",
          title: t("Login Successful!"),
          text: res?.data?.message,
          showConfirmButton: true,
          confirmButtonText: t("OK"),
          timer: 2000,
          timerProgressBar: true,
        }).then(() => {
          navigate("/dashboard");
        });
        if (rememberMe === true) {
          localStorage.setItem("rememberedEmail", data.email);
        } else {
          localStorage.removeItem("rememberedEmail");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Any cleanup actions
    }
  };

  const handleRememberMe = () => {
    const newRememberMe = !rememberMe;
    setRememberMe(newRememberMe);
    localStorage.setItem("rememberMe", JSON.stringify(newRememberMe));
  };

  return (
    <div className={`${i18n.language !== "he" ? "rtl" : "ltr"}`}>
      <section className="login_page">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-10 col-11">
              <div className="login_top shadow">
                <div className="login_page_form">
                  <div className="row">
                    <div className="col-12 formheader mb-5 text-center">
                      <h1>{t("Login")}</h1>
                      <p>{t("Please enter your email and password")}</p>
                    </div>
                    <div className="col-12">
                      <form
                        className="row form-design"
                        action=""
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="form-group col-12">
                          <label htmlFor="email">{t("User Name")}</label>
                          <input
                            type="email"
                            className={classNames("form-control mb-1  ", {
                              "is-invalid": errors.email,
                            })}
                            placeholder={t("User@gmail.com")}
                            name="email"
                            id="email"
                            {...register("email", {
                              required: t("Email is Required*"),
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: t("Invalid email address"),
                              },
                            })}
                          />
                          {errors.email && (
                            <small className="errorText mx-0 fw-bold text-danger">
                              {errors.email?.message}
                            </small>
                          )}
                        </div>

                        <div className="form-group col-12 position-relative">
                          <label htmlFor="password">{t("Password")}</label>
                          <div className="password-wrapper">
                            <input
                              type={showPassword ? "text" : "password"}
                              className={classNames("form-control mb-1", {
                                "is-invalid": errors.password,
                              })}
                              placeholder="**********"
                              name="password"
                              id="password"
                              autoComplete="password"
                              {...register("password", {
                                required: t("Password is required*"),
                              })}
                            />
                            <span
                              className={`show-pass eye ${
                                errors?.password
                                  ? `${
                                      i18n.language !== "he" ? "me-4" : "ms-4"
                                    }`
                                  : ""
                              }`}
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <i className="fa fa-eye" />
                              ) : (
                                <i className="fa fa-eye-slash" />
                              )}
                            </span>
                          </div>
                          {errors?.password && (
                            <small className="errorText mx-0 fw-bold text-danger">
                              {errors?.password?.message}
                            </small>
                          )}
                        </div>

                        <div className="form-group col-12">
                          <Link className="for_got" to="/forgot-password">
                            {t("Forgot Password?")}
                          </Link>
                        </div>
                        <div className="form-group col-12 mb-0 mt-2">
                          <button
                            type={loader ? "button" : "submit"}
                            className="comman_btn border-0"
                            style={{
                              cursor: loader ? "not-allowed" : "pointer",
                            }}
                          >
                            <span>
                              {loader ? (
                                <div className="d-flex align-items-center fw-bold justify-content-center">
                                  {t("Wait")}{" "}
                                  <Spinner
                                    style={{
                                      fontSize: "2em",
                                      height: "25px",
                                      width: "25px",
                                      marginLeft: "5px",
                                    }}
                                  />
                                </div>
                              ) : (
                                t("Login")
                              )}
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Adminlogin;
