import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "../loader/Loader";

const Commissionerlogin = lazy(() =>
  import("../commissioner/commissionerauth/Commissionerlogin")
);
// const ForgetPassword = lazy(() => import("../components/admin/ForgetPassword"));
const ViewProfile = lazy(() => import("../components/admin/ViewProfile"));
const ChangePassword = lazy(() => import("../components/admin/ChangePassword"));
const TeamsLeaguePastMatches = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/TeamsLeaguePastMatches"
  )
);
const CommissionerDasboard = lazy(() =>
  import("../commissioner/commissionerDashboard/CommissionerDasboard")
);
const Notifications = lazy(() =>
  import("../commissioner/Notifications/Notifications")
);
const ClientsSchoolDetails = lazy(() =>
  import("../commissioner/commissionercoach/ClientsSchoolDetails")
);

const CommissionerVarification = lazy(() =>
  import("../commissioner/commissionerauth/CommissionerVarification")
);
const CommissionerResetPassword = lazy(() =>
  import("../commissioner/commissionerauth/CommissionerResetPassword")
);
const CommissionerCoach = lazy(() =>
  import("../commissioner/commissionercoach/CommissionerCoach")
);
const Revenue = lazy(() => import("../commissioner/revenue/Revenue"));
const RevenueDetails = lazy(() =>
  import("../commissioner/revenue/RevenueDetails")
);
const CommissionerCheatDetectionManagment = lazy(() =>
  import(
    "../commissioner/commissionercheatdetection/CommissionerCheatDetectionManagment"
  )
);
const CommissionerCheatFormView = lazy(() =>
  import("../commissioner/commissionercheatdetection/CommissionerCheatFormView")
);
const Push = lazy(() => import("../commissioner/pushnotification/Push"));
const Earn = lazy(() => import("../commissioner/earningmanagement/Earn"));
const CommissionerContent = lazy(() =>
  import("../commissioner/commissionercontent/CommissionerContent")
);
const CommissionerContentDetails = lazy(() =>
  import("../commissioner/commissionercontent/CommissionerContentDetails")
);
const HelpCommi = lazy(() => import("../commissioner/helpsupport/HelpCommi"));
const AddNewQuery = lazy(() =>
  import("../commissioner/helpsupport/AddNewQuery")
);
const HelpDetails = lazy(() =>
  import("../commissioner/helpsupport/HelpDetails")
);
const CommissionerLeagueManagement = lazy(() =>
  import("../commissioner/commissionerleague/CommissionerLeagueManagement")
);
const CommissionerAddTeamsLeague = lazy(() =>
  import("../commissioner/commissionerleague/CommissionerAddTeamsLeague")
);
const CommissionerAddSponsorship = lazy(() =>
  import("../commissioner/commissionersponrship/CommissionerAddSponsorship")
);
const CommissionerSponsership = lazy(() =>
  import("../commissioner/commissionersponrship/CommissionerSponsership")
);
const CommissionerRecentcreatedleague = lazy(() =>
  import(
    "../commissioner/commissionerDashboard/CommissionerRecentcreatedleague"
  )
);
const CommissionerSponsorshipEdit = lazy(() =>
  import("../commissioner/commissionersponrship/CommissionerSponsorshipEdit")
);
const CommissionerSponsorshipDetails = lazy(() =>
  import("../commissioner/commissionersponrship/CommissionerSponsorshipDetails")
);
const CommissionerTeamsCityLeauges = lazy(() =>
  import(
    "../commissioner/commissionerleague/LeagueTeamsTournament/CityLeagues/CommissionerTeamsCityLeauges"
  )
);
const Individualcityleagues = lazy(() =>
  import(
    "../commissioner/commissionerleague/LeagueIndividualTournament/CityLeagues/Individualcityleauges"
  )
);
const Teamsregionalleagues = lazy(() =>
  import(
    "../commissioner/commissionerleague/LeagueTeamsTournament/RegionalLeagues/Teamsregionalleauges"
  )
);
const ComIndividualRegionalLeauges = lazy(() =>
  import(
    "../commissioner/commissionerleague/LeagueIndividualTournament/RegionalLeagues/ComIndividualRegionalLeauges"
  )
);
const TeamsRegionalEditCommi = lazy(() =>
  import(
    "../commissioner/commissionerleague/LeagueTeamsTournament/RegionalLeagues/TeamsRegionalEditCommi"
  )
);
const TeamCoachDetailsComi = lazy(() =>
  import("../commissioner/commissionercoach/teamcoach/TeamCoachDetailsComi")
);
const CommissionerSponserTournDetails = lazy(() =>
  import(
    "../commissioner/commissionersponrship/CommissionerSponserTournDetails"
  )
);
const CommissionerViewprofile = lazy(() =>
  import("../commissioner/commissionerauth/CommissionerViewprofile")
);
const SettingCommissioner = lazy(() =>
  import("../commissioner/commissionerauth/SettingCommissioner")
);
const Leaugesponsorshipdetails = lazy(() =>
  import("../commissioner/commissionerleague/Leaugesponsorshipdetails")
);

const Leaguecommissionerdetails = lazy(() =>
  import("../commissioner/commissionerleague/Leaguecommissionerdetails")
);

const routes = [
  // { path: "/commissioner/login", Component: Commissionerlogin },
  // { path: "/forgot-password", Component: ForgetPassword },
  {
    path: "/verification",
    protected: true,
    Component: CommissionerVarification,
  },
  {
    path: "/all-notifications",
    protected: true,
    Component: Notifications,
  },
  {
    path: "/reset-password",
    protected: true,
    Component: CommissionerResetPassword,
  },
  { path: "/view-profile", protected: true, Component: ViewProfile },
  { path: "/change-password", protected: true, Component: ChangePassword },
  { path: "/dashboard", protected: true, Component: CommissionerDasboard },
  {
    path: "/recent-created-leagues/:id",
    protected: true,
    Component: CommissionerRecentcreatedleague,
  },
  {
    path: "/league-management",
    protected: true,
    Component: CommissionerLeagueManagement,
  },
  {
    path: "/add-teams-league",
    protected: true,
    Component: CommissionerAddTeamsLeague,
  },
  {
    path: "/Commissioner/leauge-sponsorship-details/:id",
    protected: true,
    Component: Leaugesponsorshipdetails,
  },
  {
    path: "/teams-league-past-matches",
    protected: true,
    Component: TeamsLeaguePastMatches,
  },
  {
    path: "/commissioner/coach-management",
    protected: true,
    Component: CommissionerCoach,
  },
  {
    path: "/cheat-detection-managment",
    protected: true,
    Component: CommissionerCheatDetectionManagment,
  },
  {
    path: "/cheat-form-view/:id",
    protected: true,
    Component: CommissionerCheatFormView,
  },
  {
    path: "/content-management",
    protected: true,
    Component: CommissionerContent,
  },
  {
    path: "/sponsorship-management",
    protected: true,
    Component: CommissionerSponsership,
  },
  {
    path: "/add-sponsorship",
    protected: true,
    Component: CommissionerAddSponsorship,
  },
  {
    path: "/sponsorship-edit",
    protected: true,
    Component: CommissionerSponsorshipEdit,
  },
  {
    path: "/sponsorship-details",
    protected: true,
    Component: CommissionerSponsorshipDetails,
  },
  { path: "/push-notification", protected: true, Component: Push },
  { path: "/earning-management", protected: true, Component: Earn },
  { path: "/add-new-query", protected: true, Component: AddNewQuery },
  {
    path: "/commissioner/help-details/:id",
    protected: true,
    Component: HelpDetails,
  },
  {
    path: "/commissioner/teams-city-leauges/:id",
    protected: true,
    Component: CommissionerTeamsCityLeauges,
  },
  {
    path: "/commissioner/league-commissioner-details/:id",
    protected: true,
    Component: Leaguecommissionerdetails,
  },

  {
    path: "/commissioner/sponsor-details/:id",
    protected: true,
    Component: CommissionerSponsorshipDetails,
  },
  {
    path: "/commissioner/sponsor-tour-details/:id",
    protected: true,
    Component: CommissionerSponserTournDetails,
  },
  {
    path: "/commissioner/teams-individual-city-leauges/:id",
    protected: true,
    Component: Individualcityleagues,
  },
  {
    path: "/commissioner/teams-regional-leauges/:id",
    protected: true,
    Component: Teamsregionalleagues,
  },
  {
    path: "/commissioner/individual-regional-leauges/:id",
    protected: true,
    Component: ComIndividualRegionalLeauges,
  },
  {
    path: "/commissioner/league-edit/:id",
    protected: true,
    Component: TeamsRegionalEditCommi,
  },
  {
    path: "/commissioner/team-coach-details/:id",
    protected: true,
    Component: TeamCoachDetailsComi,
  },
  {
    path: "/commissioner/view-profile",
    protected: true,
    Component: CommissionerViewprofile,
  },
  {
    path: "/commissioner/settings",
    protected: true,
    Component: SettingCommissioner,
  },
  {
    path: "/commissioner/content-details/:id",
    protected: true,
    Component: CommissionerContentDetails,
  },
  { path: "/commissioner/help", protected: true, Component: HelpCommi },
  { path: "/revenue-management", protected: true, Component: Revenue },
  { path: "/revenue-details/:id", protected: true, Component: RevenueDetails },
  {
    path: "/clients-school-details/:id",
    protected: true,
    Component: ClientsSchoolDetails,
  },
];

const CommissionerRoutes = () => (
  <Suspense
    fallback={
      <div>
        <Loader />
      </div>
    }
  >
    <Routes>
      {routes?.map(({ path, Component, protected: isProtected }) => (
        <Route
          key={path}
          path={path}
          element={
            isProtected ? (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ) : (
              <Component />
            )
          }
        />
      ))}
    </Routes>
  </Suspense>
);

export default CommissionerRoutes;
