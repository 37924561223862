import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./animations.css";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [previousStatus, setPreviousStatus] = useState(navigator.onLine);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    setIsMounted(true);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (isMounted && isOnline !== previousStatus) {
      if (isOnline) {
        // const audio = new Audio(require("../../assets/confirmation.wav"));
        // audio?.play();
        Swal.fire({
          icon: false,
          title: "You are back online!",
          text: "All features are now available.",
          imageUrl: require("../../assets/img/logo.png"),
          imageWidth: 400,
          imageHeight: 100,
          customClass: {
            popup: "custom-swal-popup",
            image: "custom-pulse-image",
          },
          imageAlt: "Offline",
          timer: 2000,
          timerProgressBar: true,
          showClass: {
            popup: "custom-fade-in",
          },
          hideClass: {
            popup: "custom-fade-out",
          },
        });
      } else {
        // const audio = new Audio(require("../../assets/software.wav"));
        // audio?.play();
        Swal.fire({
          icon: "error",
          title: "No internet connection",
          text: "Some features may be unavailable.",
          imageUrl: require("../../assets/img/logo.png"),
          imageWidth: 400,
          imageHeight: 100,
          customClass: {
            popup: "custom-swal-popup",
            image: "custom-pulse-image",
          },
          imageAlt: "Offline",
          showClass: {
            popup: "custom-fade-in",
          },
          hideClass: {
            popup: "custom-fade-out",
          },
        });
      }
      setPreviousStatus(isOnline);
    }
  }, [isOnline, previousStatus, isMounted]);

  return null;
};

export default NetworkStatus;
