// localSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const localSliceCommissioner = createSlice({
  name: "localcommissioner",
  initialState: {
    tokencommissioner:
      localStorage.getItem("mindleagueTokencommissioner") || null,
    useridcommissioner:
      localStorage.getItem("mindleaguecommissionerloginId") || null,
    headercommissioner: null,
    profiledropcommissioner: null,

    emailAuthcommissioner:
      localStorage.getItem("emailAuthcommissioner") || null,
    leagueType: "Team",
  },
  reducers: {
    setTokencommissioner: (state, action) => {
      state.tokencommissioner = action.payload;
      localStorage.setItem("mindleagueTokencommissioner", action.payload);
    },
    setUserIdcommissioner: (state, action) => {
      state.useridcommissioner = action.payload;
      localStorage.setItem("mindleaguecommissionerloginId", action.payload);
    },
    setCommissionerEmail: (state, action) => {
      state.emailAuthcommissioner = action.payload;
      localStorage.setItem("emailAuthcommissioner", action.payload);
    },
    setHeadercommissioner: (state, action) => {
      state.headercommissioner = action.payload;
    },
    setProfiledropdowncommissioner: (state, action) => {
      state.profiledropcommissioner = action.payload;
    },
    setLeagueType: (state, action) => {
      state.leagueType = action.payload;
    },
  },
});

export const {
  setTokencommissioner,
  setUserIdcommissioner,

  setHeadercommissioner,
  setCommissionerEmail,
  setProfiledropdowncommissioner,
  setLeagueType,
} = localSliceCommissioner.actions;
export default localSliceCommissioner.reducer;
