import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setToken, setUserId, setUserType } from "../../app/slices/localSlice";

function Language() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("mindleagueTokenAdmin");
    const userId = localStorage.getItem("mindleagueAdminloginId");
    const userType = localStorage.getItem("mindlagueusertype");

    console.log("token && userId && userType", token, userId, userType);

    if (token && userId && userType) {
      dispatch(setToken(token));
      dispatch(setUserId(userId));
      dispatch(setUserType(userType));
      navigate("/dashboard");
    }
  }, []);

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <section className="login_page">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-10 col-11">
            <div className="login_top shadow">
              <div className="login_page_form">
                <div className="row">
                  <div className="col-12 formheader mb-4 text-center">
                    <h1>{t("Choose Your Language")}</h1>
                  </div>
                  <div className="col-12">
                    <form className="row form-design" action="">
                      <div className="language_bax mb-4">
                        <div className="flag-lists translation-links d-flex justify-content-center p-0 w-100">
                          <div
                            className={`flag-lists_in ${
                              i18n.language !== "he" ? "active" : ""
                            }`}
                          >
                            <a
                              className="english shadow"
                              data-lang="English"
                              href="#"
                              onClick={() => handleLangChange("en")}
                            >
                              <img
                                className="mr-md-2 ml-md-0 ml-1 flag_img mx-2"
                                src="assets/img/united-kingdom.png"
                                alt="English"
                              />
                              <span>{t("English")}</span>
                            </a>
                          </div>
                          <div
                            className={`flag-lists_in ${
                              i18n.language === "he" ? "active" : ""
                            }`}
                          >
                            <a
                              className="arabic shadow"
                              data-lang="Arabic"
                              href="#"
                              onClick={() => handleLangChange("he")}
                            >
                              <img
                                className="mr-md-2 ml-md-0 ml-1 flag_img mx-2"
                                src="assets/img/Israle__81703.jpg"
                                alt="Arabic"
                              />
                              <span>{t("Hebrew")}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 mb-0 mt-2">
                        <Link className="comman_btn" to="/login">
                          <span>{t("Submit")}</span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Language;
