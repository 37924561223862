import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { PostApi } from "../services/Post";
import { PostApicommissioner } from "../services/Postcommissioner";
import { localSlice } from "./slices/localSlice";
import { localSliceCommissioner } from "./slices/localSliceCommissioner";
import subscriptionReducer from "./slices/subscriptionSlice";

export const store = configureStore({
  reducer: {
    [PostApi.reducerPath]: PostApi.reducer,
    [PostApicommissioner.reducerPath]: PostApicommissioner.reducer,
    local: localSlice.reducer,
    localcommissioner: localSliceCommissioner.reducer,
    subscription: subscriptionReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(PostApi.middleware)
      .concat(PostApicommissioner.middleware),
});

setupListeners(store.dispatch);
