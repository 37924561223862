import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setHeader } from "../../app/slices/localSlice";
import { useTranslation } from "react-i18next";

function Sidebar({ Dash }) {
  const isSidebarVisible = useSelector((state) => state?.local?.header);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();

    Swal.fire({
      title: t("Logout Confirmation"),
      text: t("Are you sure you want to logout?"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("Yes, Logout"),
      cancelButtonText: t("No, Stay Logged In"),
    }).then((result) => {
      if (result.isConfirmed) {
        const rememberMe = localStorage.getItem("rememberMe");
        const rememberedEmail = localStorage.getItem("rememberedEmail");

        localStorage.clear();
        if (rememberMe) localStorage.setItem("rememberMe", rememberMe);
        if (rememberedEmail)
          localStorage.setItem("rememberedEmail", rememberedEmail);

        navigate("/login");
        setTimeout(() => window.location.reload(), 500);

        Swal.fire(
          t("Logged Out"),
          t("You have been successfully logged out."),
          "success"
        );
      } else {
        Swal.fire(t("Cancelled"), t("You are still logged in."), "info");
      }
    });
  };

  const handleLinkClick = () => dispatch(setHeader(""));

  const links = [
    {
      to: "/dashboard",
      key: "dash",
      icon: "fas fa-home",
      label: t("Dashboard"),
    },
    {
      to: "/student-players-management",
      key: "stu",
      icon: "fas fa-user-graduate",
      label: t("Student/Players"),
    },
    {
      to: "/alumni-management",
      key: "alumni",
      icon: "fas fa-books",
      label: t("Alumni Management"),
    },
    {
      to: "/team-managers-management",
      key: "teammanager",
      icon: "fas fa-users",
      label: t("Team Managers"),
    },
    {
      to: "/league-management",
      key: "league",
      icon: "fas fa-trophy",
      label: t("League"),
    },
    {
      to: "/school-management",
      key: "scm",
      icon: "fas fa-school",
      label: t("School Management"),
    },
    {
      to: "/commissioners-management",
      key: "commi",
      icon: "fas fa-user",
      label: t("Commissioners"),
    },
    {
      to: "/coach-management",
      key: "cm",
      icon: "fas fa-chalkboard-teacher",
      label: t("Coach"),
    },
    {
      to: "/sponsar-management",
      key: "spm",
      icon: "far fa-handshake",
      label: t("Sponsor Management"),
    },
    {
      to: "/subscription-plan-management",
      key: "subs",
      icon: "fas fa-hand-holding-usd",
      label: t("Subscription Plan"),
    },
    {
      to: "/payout-commissioner-management",
      key: "pay",
      icon: "fas fa-coins",
      label: t("Payout Management"),
    },
    {
      to: "/transaction-management",
      key: "trans",
      icon: "fas fa-sync-alt",
      label: t("Transaction"),
    },
    {
      to: "/cheat-detection-managment",
      key: "cheat",
      icon: "fas fa-street-view",
      label: t("Cheat Detection"),
    },
    {
      to: "/all-notifications",
      key: "notify",
      icon: "far fa-bell",
      label: t("Notifications"),
    },
    {
      to: "/push-notifications",
      key: "push",
      icon: "fas fa-bell",
      label: t("Push Notifications"),
    },
    {
      to: "/help-support",
      key: "help",
      icon: "fas fa-headset",
      label: t("Help & Support"),
    },
    {
      to: "/content-management",
      key: "content",
      icon: "fas fa-edit",
      label: t("Content Management"),
    },
  ];

  return (
    <div className={`admin_siderbarr ${isSidebarVisible ? "showw" : ""}`}>
      {links.map(({ to, key, icon, label }) => (
        <a
          key={key}
          className={Dash === key ? "active" : ""}
          href={to}
          onClick={handleLinkClick}
        >
          <span>
            <i className={icon} />
          </span>
          {label}
        </a>
      ))}
      <Link
        to="#"
        onClick={(e) => {
          handleLogout(e);
          handleLinkClick();
        }}
      >
        <span>
          <i className="fas fa-sign-out-alt" />
        </span>
        {t("Logout")}
      </Link>
    </div>
  );
}

export default Sidebar;
